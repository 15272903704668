<template>
  <div>
    <section id="mainContent" class="relative w-screen min-h-screen pointer-events-none flex items-center">
      <div id="contentHolder" class="w-screen dkblue pointer-events-auto">
        <div>
          <h1>Sign up.</h1>
          <p>
            Fill out the information below to set when you get notified for your group practice time.
          </p>
          <div>
            <form id="signupForm" class="relative" @submit="signupSubmitted">
              <label class="block grid grid-cols-2">
                <custominput
                  placeholder="First Name"
                  ref="firstName"
                  id="firstName"
                  v-model.lazy="firstname"
                  class="mt-1 w-half"
                />
                <custominput
                  id="lastName"
                  v-model="lastname"
                  type="text"
                  class="form-input mt-1 w-half"
                  placeholder="Last Name"
                />
              </label>
              <label class="block">
                <custominput
                  id="email"
                  type="email"
                  v-model="email"
                  class="form-input mt-1 block w-full"
                  placeholder="Email Address"
                  autocomplete="email"
                />
              </label>
              <label class="block grid grid-cols-2">
                <div class="inline-block relative mt-1 w-half">
                  <div class="absolute inset-y-0 right-0 flex items-center px-2">
                    <input class="hidden js-password-toggle" id="toggle" type="checkbox" />
                    <label
                      @click="passwordToggle"
                      class="hover:bg-gray-200 px-2 py-1 text-sm opacity-40 cursor-pointer js-password-label"
                      for="toggle"
                    >
                      <img
                        v-if="showPassword"
                        class="transform scale-75"
                        src="@/assets/img/icons/eye-blocked.svg"
                        alt="hide password"
                      />
                      <img
                        v-if="!showPassword"
                        class="transform scale-75"
                        src="@/assets/img/icons/eye.svg"
                        alt="show password"
                      />
                    </label>
                  </div>
                  <custominput
                    class="form-input appearance-none border-2 rounded w-full py-3 px-3  focus:outline-none focus:border-grey-700 focus:bg-white pr-16 js-password"
                    id="password"
                    v-model="password"
                    type="password"
                    autocomplete="new-password"
                    placeholder="Password"
                    aria-describedby="password"
                    aria-invalid="false"
                    @change="validatePassword"
                    title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                    required
                  />
                </div>
                <custominput
                  @keyup="validatePassword"
                  id="confirm_password"
                  autocomplete="new-password"
                  type="password"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Please re-enter the same password to confirm"
                  class="form-input mt-1 w-half"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  v-model="confirm_password"
                  aria-describedby="confirm password"
                  aria-invalid="false"
                  required
                />
              </label>
              <label class="block text-sm sans text-white text-left italic px-2"
                >Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or
                more characters</label
              >
              <label class="block">
                <TimezoneDD ref="timezone"></TimezoneDD>
              </label>
              <div class="block">
                <div class="mt-2">
                  <div>
                    <div class="inline-flex items-center">
                      <input type="checkbox" class="relative form-checkbox" required />
                      <span class="ml-2 text-left text-white serif"
                        >By checking this box I acknowledge that I have read and agree to the Submission
                        <router-link class="cursor-pointer" target="_blank" to="terms-and-conditions/"
                          >Terms and Conditions</router-link
                        >
                        and to the
                        <router-link class="cursor-pointer" target="_blank" to="privacy-policy/"
                          >Privacy Policy</router-link
                        >.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="block mx-0 text-left">
                <button type="submit" value="SUBMIT" class="btn yellow rounded-sm hover:bg-white">SUBMIT</button>
                <router-link tag="button" to="/" class="btn bg-blue opacity-85 rounded-sm hover:bg-white"
                  >CANCEL</router-link
                >
                <!-- <button class="btn bg-yellow rounded-sm">SUBMIT</button> -->
                <!-- <button class="btn bg-blue opacity-85 rounded-sm">CANCEL</button> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios';
import TimezoneDD from '@/components/timezonedd.vue';
import custominput from '@/components/custominput.vue';

export default {
  components: { TimezoneDD, custominput },
  data: () => ({
    showPassword: false,
    responseMsg: '',
    loggedIn: false,
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirm_password: '',
    value: '',
  }),
  mounted() {
    // const tOffset = -(new Date().getTimezoneOffset() / 60);
    // document.querySelector('#timezone').value = String(tOffset);
    // console.log(String(tOffset));
    this.$store.watch(
      (state) => {
        return state.user.authenticated;
      },
      ((newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.loggedIn = newVal;
          this.$forceUpdate();
        }
      }).bind(this),
      {}
    );
  },
  watch: {},
  methods: {
    // save category information
    saveSession: function(token, timezone) {
      // var catNum = window.localStorage.length == 5 ? 0 : window.localStorage.length + 1;
      // var categoryName = 'category' + catNum;
      // console.log('saving category:', categoryName, newCategory);
      window.localStorage.setItem('userToken', token);
      window.localStorage.setItem('userTimezone', timezone);
    },

    onSignedUp: function(response) {
      // Handle success.
      console.log(response);
      console.log('Well done!');
      console.log('User profile', response.data.user);
      console.log('User token', response.data.jwt);
      this.$store.dispatch('setUserStatus', { target: 'authenticated', status: true });
      this.$store.dispatch('setUserStatus', { target: 'username', status: response.data.user.username });
      this.$store.dispatch('setUserStatus', { target: 'firstname', status: response.data.user.FirstName });
      this.$store.dispatch('setUserStatus', { target: 'lasttname', status: response.data.user.LastName });
      this.$store.dispatch('setUserStatus', { target: 'timezone', status: response.data.user.timezone });
      this.$store.dispatch('setUserStatus', { target: 'token', status: response.data.jwt });
      this.saveSession(response.data.jwt, response.data.user.timezone);
      this.$router.push('/signedup');
    },
    onSignupError: function(error) {
      console.log(JSON.stringify(error));
      this.responseMsg = JSON.stringify(
        `We were unable to sign you up at this time. Please share this information with our admin:\n ${JSON.stringify(
          error
        )}`
      );
    },
    // toggleLoggedIn() {
    //   this.$store.dispatch('setUserStatus', { target: 'authenticated', status: !this.loggedIn });
    // },
    signupSubmitted: function(e) {
      const firstname = document.getElementById('firstName').value;
      const lastname = document.getElementById('lastName').value;
      const username = `${firstname.replace(/[^a-zA-Z ]/g, '')}${lastname.replace(/[^a-zA-Z ]/g, '')}_${String(
        new Date().getTime()
      )}`;
      const email = document.getElementById('email').value;
      const timezone = String(document.getElementById('timezone').value);
      const password = document.getElementById('password').value;

      var data = JSON.stringify({
        firstName: firstname,
        lastName: lastname,
        username: username,
        email: email,
        creationDate: new Date(),
        password: password,
        timeZone: timezone,
      });

      // console.log(data);
      var config = {
        method: 'post',
        url: this.$store.state.authURL + '/register',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      axios(config)
        .then(this.onSignedUp)
        .catch(this.onSignupError);

      e.preventDefault();
    },
    validatePassword: function() {
      const password = document.getElementById('password');
      const confirm_password = document.getElementById('confirm_password');
      if (password.value != confirm_password.value) {
        confirm_password.setCustomValidity("Passwords Don't Match");
      } else {
        confirm_password.setCustomValidity('');
      }
    },
    passwordToggle: function() {
      const password = document.querySelector('.js-password');

      if (password.type === 'password') {
        password.type = 'text';
        this.showPassword = true;
      } else {
        this.showPassword = false;
        password.type = 'password';
      }

      password.focus();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/helpers';
@import '../styles/typovars';

section {
  position: relative;
}
button {
  margin: 0px 21px 15px 0px;
}
#mainContent {
  .grid {
    padding-bottom: 0px;
  }
}
#signupForm {
  .text-white {
    a {
      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }

      text-decoration: underline;
    }
  }
}
#firstName,
#password {
  margin-right: 7px;
}
#lastName,
#confirm_password {
  margin-left: 7px;
}
</style>
